import React, { useMemo } from 'react';
import Accordion from 'components/bricks/components/shared/components/accordion';
import { useAdSetupContext } from 'components/bricks/components/shared/components/ad-setup/context/ad-setup-context';
import Translation from 'components/data/Translation';
import { FieldSetCopy } from 'components/input/Copy';
import CreativeSelector from 'components/input/CreativeSelector';
import Divider from 'components/ui-components-v2/Divider';
import { CreativeV2 } from 'components/creatives-v2/components/creative-editor/types/creativeV2.type';
import { FieldSetSelectInput } from 'components/input/SelectInput';
import TextField from 'components/ui-components-v2/TextField';
import { FieldSetAutocomplete } from 'components/input/AutoComplete';
import ValidateHelpers from 'components/bricks/helpers/validate.helpers';
import AdSetupInputWrapper from 'components/bricks/components/shared/components/ad-setup/components/ad-setup-input-wrapper';
import brickSetup from './setup';
import youtubeCategoryOptions from '../data/categories';
import privacyStatusOptions from '../data/privacy-status';

interface Props {
    handleSelectCreatives: (creatives: CreativeV2[]) => void;
}

const SingleFrame: React.FC<Props> = ({ handleSelectCreatives }) => {
    const { adSetup, creatives, updateAdSetupProperty } = useAdSetupContext();

    const inputValidators = useMemo(() => {
        return {
            title: ValidateHelpers.getInputValidators('youtube_post', `youtube_post-ad_setup-title`),
            description: ValidateHelpers.getInputValidators('youtube_post', `youtube_post-ad_setup-description`)
        };
    }, []);
    const youtubeAdSetup: any = adSetup; // TODO Temporary because the adsetup is typed for meta and not in general

    const { multiple, canEditCreative, selectors, templateTypes = [], fileType } = brickSetup?.config?.general?.creativeSelector || {};

    return (
        <Accordion
            square
            title={Translation.get('adSetup.main', 'bricks')}
            classes={{ root: 'ad-setup__accordion__root', details: 'ad-setup__accordion__details', summaryRoot: 'ad-setup__accordion__summary__root' }}>
            <div className="ad-setup__accordion__inputs">
                <CreativeSelector
                    onMutation={(creatives) => handleSelectCreatives(creatives)}
                    multiple={multiple}
                    canEditCreative={canEditCreative}
                    value={creatives}
                    selectors={selectors}
                    templateTypes={templateTypes}
                    fileType={fileType}
                    isCompact
                />
                <Divider />

                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.youtube.title', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('snippet.title', newValue, true)}
                        value={youtubeAdSetup?.items?.[0].snippet?.title}
                        validators={inputValidators.title}
                    />
                </AdSetupInputWrapper>

                <AdSetupInputWrapper>
                    <FieldSetCopy
                        label={Translation.get('adSetup.youtube.description', 'bricks')}
                        useValueObject={false}
                        onMutation={(newValue) => updateAdSetupProperty('snippet.description', newValue, true)}
                        value={youtubeAdSetup?.items?.[0].snippet?.description}
                        validators={inputValidators.description}
                    />
                </AdSetupInputWrapper>

                <AdSetupInputWrapper>
                    <FieldSetAutocomplete
                        multiple
                        freeSolo
                        options={[]}
                        size="small"
                        label={Translation.get('adSetup.youtube.keywords', 'bricks')}
                        value={youtubeAdSetup?.items?.[0].snippet?.keywords || []}
                        onMutation={(newValue) => updateAdSetupProperty('snippet.keywords', newValue, true)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={Translation.get('adSetup.youtube.keywords', 'bricks')}
                                placeholder="Enter keywords"
                            />
                        )}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetSelectInput
                        label={Translation.get('adSetup.youtube.category', 'bricks')}
                        useValueObject={false}
                        value={youtubeAdSetup.items?.[0].snippet?.category}
                        onMutation={(newValue) => updateAdSetupProperty('snippet.category', newValue, true)}
                        options={youtubeCategoryOptions}
                    />
                </AdSetupInputWrapper>
                <AdSetupInputWrapper>
                    <FieldSetSelectInput
                        label={Translation.get('adSetup.youtube.privacyStatus', 'bricks')}
                        options={privacyStatusOptions}
                        value={youtubeAdSetup?.items?.[0].status?.privacyStatus}
                        onMutation={(newValue) => updateAdSetupProperty('status.privacyStatus', newValue, true)}
                    />
                </AdSetupInputWrapper>
            </div>
        </Accordion>
    );
};

export default SingleFrame;
