import React, { useState } from 'react';
import MenuItem from 'components/ui-components-v2/MenuItem';
import CircularProgress from 'components/ui-components-v2/CircularProgress';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import SplitButton from 'components/ui-components-v2/SplitButton';
import { Brick, BrickPublishStatus } from 'components/bricks/types/brick.type';
import { openSlidePanel } from 'components/bricks/helpers/slide-panel.helpers';
import { BrickPublishJobData } from 'components/bricks/hooks/useBricksPublish';
import User from 'components/data/User';
import OutputJobsDialog from '../output-jobs-dialog';
import './styles/main.scss';

interface PublishButtonProps {
    bricks: Brick[];
    variant?: 'contained' | 'outlined' | 'text';
    className?: string;
    onPublish?: (publish: BrickPublishJobData[]) => void;
}

const PublishButton: React.FC<PublishButtonProps> = ({ bricks, variant = 'outlined', className, onPublish }) => {
    const [publishDialogOpen, setPublishDialogOpen] = useState(false);
    const brick = bricks[0];
    const status: BrickPublishStatus = brick.data?.publish?.publishStatus;
    const brickIds = bricks.map((brick) => brick.id);

    const canPublish = User.hasRight(['brickManagement', 'brickPublish']);

    if (!canPublish) return null;

    const getPublishButton = () => {
        if (status === 'readyToPublish' || 1 === 1) {
            return (
                <Button onClick={() => setPublishDialogOpen(true)} fullWidth size={'small'} variant={variant}>
                    Publish
                </Button>
            );
        }
        if (status === 'publishing') {
            return (
                <Button fullWidth variant="outlined">
                    <CircularProgress size={16} />
                </Button>
            );
        }
        if (status === 'incomplete') {
            return (
                <Button fullWidth size={'small'} variant="outlined" onClick={() => openSlidePanel(brick)}>
                    Missing info
                </Button>
            );
        }
        if (status === 'published' || status === 'failed') {
            return (
                <SplitButton
                    buttonGroupProps={{ variant: 'outlined' }}
                    mainButton={<Button onClick={() => setPublishDialogOpen(true)}>Publish</Button>}
                    menuItems={[
                        <MenuItem disabled key="test2">
                            No options available
                        </MenuItem>
                    ]}
                />
            );
        }

        //should probably be an extra prop?
        if (status === 'single') {
            return (
                <Button onClick={() => setPublishDialogOpen(true)} fullWidth size={'small'} variant="outlined" endIcon={<Icon>publish</Icon>}>
                    Single
                </Button>
            );
        }
    };

    return (
        <>
            <div className={`bricks-publish-button ${className}`}>{getPublishButton()}</div>
            {publishDialogOpen && (
                <OutputJobsDialog outputAction={'publish'} brickIds={brickIds} onClose={() => setPublishDialogOpen(false)} onActionFinish={onPublish} />
            )}
        </>
    );
};

export default PublishButton;
